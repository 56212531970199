* {
  box-sizing: border-box;
}


:root {
  --body-margin: 1rem;
}

body {
  margin: 0;
  width: 100vw;
  height: 100vh;
  background-image: url(.\1.png);
  background-size: cover;
  color: white;
  font-family: 'Libre Baskerville', serif;
}
body::after {
  content: " ";
  border: 2px solid #ffffff66;
  top: var(--body-margin);
  bottom: var(--body-margin);
  left: var(--body-margin);
  right: var(--body-margin);
  position: absolute;
  pointer-events: none;
  touch-action: none;
}
ul {
padding:0
}

a {
  text-decoration: none;
  color: inherit;
}

.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.intro {
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.intro-title {
  margin:0
}
.intro-additional {
}

.social-links {
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: calc(var(--body-margin) + 1rem);
  justify-content: center;
  align-items: center;
}

.social-links_list {
  display: flex;
  justify-content: center;
  align-items: center;
}
.social-links li {
  list-style: none;
}

[class^="icon-"],
.social-links a
 {
  opacity: 0.5;
}

[class^="icon-"] {
  font-size: 1.6rem;
}

[class^="icon-"]:hover,
.social-links a:hover {
  opacity: 1 !important;
  cursor: pointer;
}

